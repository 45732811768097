import axios from "axios";

import { POPUP } from "@/state/popup";
export const ResolveAPI = function($bvToast, response, callback = {}) {
  var data;
  if (response.status) {
    data = "Success";
    POPUP.popupClick(data);
    callback.success instanceof Function && callback.success();
    return true;
  } else {
    data = "Error";
    POPUP.popupClickNot(data);
    callback.error instanceof Function && callback.error();
    return false;
  }
};
export const API_BASE = "https://www.estetikinternational.com/api/";
// export const API_BASE = "http://127.0.0.1:8000/api/";

export const API = {
  Get(url) {
    return axios.get(API_BASE + url, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
        "Access-Control-Allow-Headers":
          "Content-Type, X-Auth-Token, Origin, Authorization",
      },
    });
  },
  async Post(url, data = {}, header = {}) {
    if (data instanceof FormData) {
      data.append("token", localStorage.token);
      header = {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
      };
    } else {
      data.token = localStorage.token;
    }
    const response = await axios.post(API_BASE + url, data, {
      headers: header,
    });

    // if (response.data.reason == "admin") {
    //   return router.go("/");
    // } else if (response.data.reason == "auth") {
    //   return router.push("/login");
    // }
    return response;
  },

  LandingPage: {
    Index: "landingPage/index",
    UpdateList: "landingPage/update-list/",
    Update: "landingPage/update/",
    CreateIndex: "landingPage/create-index",
    Create: "landingPage/create",
    Status: "landingPage/status/",
    Delete: "landingPage/delete/",
    Clone: "landingPage/copy/",
  },
  Languages: {
    Index: "languages",
  },
  Auth: {
    Login: "auth/login",
    Register: "auth/register",
    CheckToken: "auth/check-token",
    userController: "auth/user-controller/",
    CompanyRegister: "auth/company-register",
  },
  Users: {
    Index: "users/index",
    UpdateList: "users/update-list/",
    Update: "users/update/",
    CreateIndex: "users/create-index",
    Create: "users/create",
    Status: "users/status/",
    IsAdmin: "users/isAdmin/",
    Marketing: "users/marketing/",
    Portfolio: "users/portfolio/",
    CallCenter: "users/callCenter/",
    Delete: "users/delete/",
  },
  Profile: {
    Index:
      "profile/index/" +
      localStorage.getItem("token") +
      "/" +
      localStorage.getItem("company"),
    Update:
      "profile/update/" +
      localStorage.getItem("token") +
      "/" +
      localStorage.getItem("company"),
    CreateUser: "profile/create/new/" + localStorage.getItem("token"),
    Delete: "profile/delete/",
    Find: "profile/find/",
  },
  Product: {
    Index: "product/index",
    Search: "product/search",
  },
  Presentation: {
    Index: "presentation/index/",
    CreateIndex: "presentation/createIndex/",
    Create: "presentation/create/",
    Status: "presentation/status/",
    Rooms: "presentation/project/",
    Delete: "presentation/delete/",
    UpdateList: "presentation/update-list/",
    Update: "presentation/update/",
  },
  Company: {
    Index: "company/index",
    Status: "company/status/",
    Delete: "company/delete/",
  },
  Lead: {
    Index: "lead/" + localStorage.getItem("token") + "/index",
    Detail: "lead/" + localStorage.getItem("token") + "/detail/",
    Filter: "lead/" + localStorage.getItem("token") + "/filter",
    phoneCheck: "lead/" + localStorage.getItem("token") + "/phone",
    Bitrix: "lead/" + localStorage.getItem("token") + "/bitrix",
  },
  Offer: {
    Index: "offer/index/",
    Create: "offer/create/",
  },
  LanguagePage: {
    Index: localStorage.getItem("token") + "/language/index",
    UpdateList: localStorage.getItem("token") + "/language/update/",
    Update: localStorage.getItem("token") + "/language/update/",
  },
  HomePage: {
    Index: localStorage.getItem("token") + "/homepage/index",
    UpdateList: localStorage.getItem("token") + "/homepage/update/",
    Update: localStorage.getItem("token") + "/homepage/update/",
    Create: localStorage.getItem("token") + "/homepage/create",
    LangList: localStorage.getItem("token") + "/homepage/langlist",
  },
  Category: {
    Index: localStorage.getItem("token") + "/category/index",
    UpdateList: localStorage.getItem("token") + "/category/update/",
    Update: localStorage.getItem("token") + "/category/update/",
    Create: localStorage.getItem("token") + "/category/create",
    CategoryList: localStorage.getItem("token") + "/category/list",
    Status: localStorage.getItem("token") + "/category/status/",
    Lang: {
      Index: localStorage.getItem("token") + "/category/lang/index/",
      UpdateList: localStorage.getItem("token") + "/category/lang/update/",
      Update: localStorage.getItem("token") + "/category/lang/update/",
      Create: localStorage.getItem("token") + "/category/lang/create/",
      CategoryList: localStorage.getItem("token") + "/category/list/",
      LangList: localStorage.getItem("token") + "/category/lang/langList/",
    },
    FAQ: {
      Index: localStorage.getItem("token") + "/category/faq/index/",
      UpdateList: localStorage.getItem("token") + "/category/faq/update/",
      Update: localStorage.getItem("token") + "/category/faq/update/",
      Create: localStorage.getItem("token") + "/category/faq/create/",
      CategoryList: localStorage.getItem("token") + "/category/list/",
      LangList: localStorage.getItem("token") + "/category/faq/langList/",
    },
    Photo: {
      UpdateList: localStorage.getItem("token") + "/category/photo/update/",
      Create: localStorage.getItem("token") + "/category/photo/create/",
    },
    Information: {
      Index: localStorage.getItem("token") + "/category/information/index/",
      UpdateList:
        localStorage.getItem("token") + "/category/information/update/",
      Update: localStorage.getItem("token") + "/category/information/update/",
      Create: localStorage.getItem("token") + "/category/information/create/",
      CategoryList: localStorage.getItem("token") + "/category/list/",
      LangList:
        localStorage.getItem("token") + "/category/information/langList/",
    },
  },
  DoctorsPage: {
    Index: localStorage.getItem("token") + "/doctorspage/index",
    UpdateList: localStorage.getItem("token") + "/doctorspage/update/",
    Update: localStorage.getItem("token") + "/doctorspage/update/",
    Create: localStorage.getItem("token") + "/doctorspage/create",
    LangList: localStorage.getItem("token") + "/doctorspage/langlist",
  },
  Doctors: {
    Index: localStorage.getItem("token") + "/doctors/index",
    UpdateList: localStorage.getItem("token") + "/doctors/update/",
    Update: localStorage.getItem("token") + "/doctors/update/",
    Create: localStorage.getItem("token") + "/doctors/create",
    CategoryList: localStorage.getItem("token") + "/doctors/list/",
    Status: localStorage.getItem("token") + "/doctors/status/",
    CerfiticateList:
      localStorage.getItem("token") + "/doctors/cerfiticate/list/",
    Cerfiticate: localStorage.getItem("token") + "/doctors/cerfiticate/",
    Techniq: {
      Index: localStorage.getItem("token") + "/doctors/techniq/index/",
      UpdateList: localStorage.getItem("token") + "/doctors/techniq/update/",
      Update: localStorage.getItem("token") + "/doctors/techniq/update/",
      Create: localStorage.getItem("token") + "/doctors/techniq/create/",
      LangList: localStorage.getItem("token") + "/doctors/techniq/langlist",
    },
    Society: {
      Index: localStorage.getItem("token") + "/doctors/society/index/",
      UpdateList: localStorage.getItem("token") + "/doctors/society/update/",
      Update: localStorage.getItem("token") + "/doctors/society/update/",
      Create: localStorage.getItem("token") + "/doctors/society/create/",
      LangList: localStorage.getItem("token") + "/doctors/society/langlist",
    },
    Photo: {
      Index: localStorage.getItem("token") + "/doctors/photo/index/",
      UpdateList: localStorage.getItem("token") + "/doctors/photo/update/",
      Update: localStorage.getItem("token") + "/doctors/update/",
      Create: localStorage.getItem("token") + "/doctors/photo/create/",
      CategoryList: localStorage.getItem("token") + "/doctors/list/",
      LangList: localStorage.getItem("token") + "/doctors/photo/langList/",
    },
    Lang: {
      Index: localStorage.getItem("token") + "/doctors/lang/index/",
      UpdateList: localStorage.getItem("token") + "/doctors/lang/update/",
      Update: localStorage.getItem("token") + "/doctors/lang/update/",
      Create: localStorage.getItem("token") + "/doctors/lang/create/",
      CategoryList: localStorage.getItem("token") + "/doctors/list/",
      LangList: localStorage.getItem("token") + "/doctors/lang/langList/",
    },
    Category: {
      Index: localStorage.getItem("token") + "/doctors/category/index/",
      Update: localStorage.getItem("token") + "/doctors/category/update/",
      List: localStorage.getItem("token") + "/doctors/category/list/",
    },
  },
  Highlights: {
    Index: localStorage.getItem("token") + "/highlights/index",
    UpdateList: localStorage.getItem("token") + "/highlights/update/",
    Update: localStorage.getItem("token") + "/highlights/update/",
    Create: localStorage.getItem("token") + "/highlights/create/",
    CategoryList: localStorage.getItem("token") + "/highlights/list/",
    Lang: {
      Index: localStorage.getItem("token") + "/highlights/lang/index/",
      UpdateList: localStorage.getItem("token") + "/highlights/lang/update/",
      Update: localStorage.getItem("token") + "/highlights/lang/update/",
      Create: localStorage.getItem("token") + "/highlights/lang/create/",
      LangList: localStorage.getItem("token") + "/highlights/lang/langList/",
    },
  },
  TopOn: {
    Index: localStorage.getItem("token") + "/topon/index",
    UpdateList: localStorage.getItem("token") + "/topon/update/",
    Update: localStorage.getItem("token") + "/topon/update/",
    Create: localStorage.getItem("token") + "/topon/create",
    LangList: localStorage.getItem("token") + "/topon/langlist",
  },
  Patient: {
    Index: localStorage.getItem("token") + "/patient/index",
    UpdateList: localStorage.getItem("token") + "/patient/update/",
    Update: localStorage.getItem("token") + "/patient/update/",
    Create: localStorage.getItem("token") + "/patient/create",
    CategoryList: localStorage.getItem("token") + "/patient/list",
    Status: localStorage.getItem("token") + "/patient/status/",
    Lang: {
      Index: localStorage.getItem("token") + "/patient/lang/index/",
      UpdateList: localStorage.getItem("token") + "/patient/lang/update/",
      Update: localStorage.getItem("token") + "/patient/lang/update/",
      Create: localStorage.getItem("token") + "/patient/lang/create/",
      CategoryList: localStorage.getItem("token") + "/patient/list/",
      LangList: localStorage.getItem("token") + "/patient/lang/langList/",
    },
  },
  PatientPage: {
    Index: localStorage.getItem("token") + "/patientpage/index",
    UpdateList: localStorage.getItem("token") + "/patientpage/update/",
    Update: localStorage.getItem("token") + "/patientpage/update/",
    Create: localStorage.getItem("token") + "/patientpage/create",
    LangList: localStorage.getItem("token") + "/patientpage/langlist",
  },
  AboutPage: {
    Index: localStorage.getItem("token") + "/aboutpage/index",
    UpdateList: localStorage.getItem("token") + "/aboutpage/update/",
    Update: localStorage.getItem("token") + "/aboutpage/update/",
    Create: localStorage.getItem("token") + "/aboutpage/create",
    LangList: localStorage.getItem("token") + "/aboutpage/langlist",
  },
  Comment: {
    Index: localStorage.getItem("token") + "/comment/index",
    UpdateList: localStorage.getItem("token") + "/comment/update/",
    Update: localStorage.getItem("token") + "/comment/update/",
    Create: localStorage.getItem("token") + "/comment/create",
    LangList: localStorage.getItem("token") + "/comment/langlist",
  },
  CRMLead: {
    Index: "crm/lead/" + localStorage.getItem("token") + "/index",
    Detail: "crm/lead/" + localStorage.getItem("token") + "/detail/",
    Filter: "crm/lead/" + localStorage.getItem("token") + "/filter",
    Campaign: "crm/lead/" + localStorage.getItem("token") + "/campaign",
    LeadSource: "crm/lead/" + localStorage.getItem("token") + "/leadSource",
    LeadMedium: "crm/lead/" + localStorage.getItem("token") + "/leadMedium",
  },
  UtmAd: {
    Index: localStorage.getItem("token") + "/utmAd/index",
    UpdateList: localStorage.getItem("token") + "/utmAd/update/",
    Update: localStorage.getItem("token") + "/utmAd/update/",
    Create: localStorage.getItem("token") + "/utmAd/create",
    Delete: localStorage.getItem("token") + "/utmAd/delete/",
  },
  WpCode: {
    Index: localStorage.getItem("token") + "/wpCode/index",
    UpdateList: localStorage.getItem("token") + "/wpCode/update/",
    Update: localStorage.getItem("token") + "/wpCode/update/",
    Create: localStorage.getItem("token") + "/wpCode/create",
  },
  CampaignType: {
    Index: localStorage.getItem("token") + "/campaign-type/index",
    Create: localStorage.getItem("token") + "/campaign-type/create",
    Month: {
      Index: localStorage.getItem("token") + "/campaign-type/month/",
      Create: localStorage.getItem("token") + "/campaign-type/month/",
      UpdateList: localStorage.getItem("token") + "/campaign-type/month/",
      Update: localStorage.getItem("token") + "/campaign-type/month/",
    },
  },
};
